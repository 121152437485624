<template>
  <div class="mt-3" v-if="!isUser" id="block">
    <h3>{{ $t('res.tb') }}</h3>
    <b-card no-body class="mt-3" header-tag="nav">
      <b-tabs card lazy active-nav-item-class="px-2 px-sm-3" content-class="p-2 p-sm-4">
        <b-tab no-body title-link-class="px-2 px-sm-3">
          <template #title>
            <b-icon-calendar-plus v-if="!isXs" v-bind="iH" /> {{ $t('res.hne') }}
          </template>
          <ReservationsBlockNew />
        </b-tab>
        <b-tab no-body active title-link-class="px-2 px-sm-3">
          <template #title>
            <b-icon-calendar3 v-if="!isXs" v-bind="iH" /> {{ $t('res.hli') }}
          </template>
          <div class="row-fluid clearfix mb-2 mb-sm-3">
            <b-button type="button" variant="primary" class="float-right" id="resRefresh" @click="getResData()">
              <Icon type="f" /> {{ $t('btn.f') }}
            </b-button>
          </div>
          <ReservationsCustomerTable ref="blkResTab" suffix="Blk"
              :query="resTabDef"
              showShow showReject showDelete showPagination 
              :noHitText="$t('res.bnoh')" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { BIconCalendarPlus, BIconCalendar3 } from 'bootstrap-vue';
import ReservationsCustomerTable from '@/components/reservations/CustomerTable';
import ReservationsBlockNew from '@/components/reservations/BlockNew';

export default {
  data() {
    return {
      resTabDef: { q: 'startAt:ge:{T},userUuid4:e:{U}', s: '+startAt', p: '10' }
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.oLogin.isLoggedIn;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    iH() {
      return this.$store.getters.iH;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
  },
  methods: {
    getResData() {
      this.$refs['blkResTab'].getResData();
    },
  },
  components: {
    ReservationsCustomerTable,
    ReservationsBlockNew,
    Icon,
    BIconCalendarPlus,
    BIconCalendar3,
  }
}
</script>
