<template>
  <b-form id="blkNew" :key="render" @submit.stop.prevent>
    <b-form-row class="flex-column flex-sm-row">
      <b-col cols="12" sm="6">
        <b-form-group :label="$t('res.fcnt')" label-for="blkNewCount" label-class="text-nowrap" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-hash />
              </b-input-group-text>
            </template>
            <b-input type="number" id="blkNewCount" v-model="f.count" :min="1" :max="500" :state="valCounted" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6">
        <b-form-group :label="$t('res.frec')" label-for="blkNewRec" label-class="text-nowrap pl-sm-4" label-cols="4">
          <b-form-select id="blkNewRec" v-model="f.recurrence" :options="recOptions" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="flex-column flex-sm-row">
      <b-col cols="12" sm="6">
        <b-form-group :label="$t('res.fda')" label-for="blkNewInputDate" label-class="text-nowrap" label-cols="4">
          <DatePicker id="blkNewInputDate" formid="blkNew" v-model="date" :state="valDates" required
              :maxYearOffset="2" :format="getDateFormat" :offset="isXs?`-100`:`0`" />
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6">
        <b-form-group :label="$t('res.fti')" label-for="blkNewInputTime" label-class="pl-sm-4" label-cols="4">
          <TimePicker id="blkNewInputTime" v-model="time" :state="valDates" required :format="getTimeFormat" formid="blkNew" />
          <b-form-valid-feedback>
            <p v-if="isMidNight" class="mb-1">{{ $t('res.fmw') }}</p>
          </b-form-valid-feedback>
          <b-form-invalid-feedback>
            <p v-if="isInPast" class="mb-1">{{ $t('res.flw') }}</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="flex-column-reverse flex-sm-row">
      <b-col cols="12" sm="6">
        <b-form-group :label="$t('res.ffo')" label-for="blkNewInputSize" label-class="text-nowrap" label-cols="4">
          <b-input-group>
            <template #prepend>
              <b-input-group-text>
                <b-icon-people />
              </b-input-group-text>
            </template>
            <b-input type="number" number id="blkNewInputSize" v-model="f.seats" :min="1" :max="20" :state="valSeats" required />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6">
        <b-form-group :label="$t('res.fdu')" label-for="resNewInputDur" label-class="text-nowrap pl-sm-4" label-cols="4">
          <b-input-group :prepend="$t('dt.tms')">
            <b-input type="number" number id="blkNewInputDur" v-model="f.duration" :min="15" :max="1440" :step="15" :state="valDur" required />
          </b-input-group>
          <b-form-invalid-feedback :state="valDur">
            <p class="mb-1">{{ $t('res.fxw') }}</p>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="flex-column flex-sm-row">
      <b-col cols="12" sm="12">
        <b-form-group :label="$t('res.fco')" label-for="blkNewInputMsg" label-cols="4" label-cols-sm="2">
          <b-textarea id="blkNewInputMsg" rows="3" v-model="f.userMessage" :maxlength="200" :placeHolder="$t('res.fcot')" :state="valMessage" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="flex-column flex-sm-row">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button type="reset" variant="primary" class="mr-2 mr-sm-3" @click="onCancel">
          <Icon type="r" /> {{ $t('btn.r') }}
        </b-button>
        <b-button type="submit" variant="secondary" :disabled="val" @click="onSubmit">
          <Icon type="res" /> {{ $t('res.btnbs') }}
        </b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import Icon from '@/components/Icon';
import { BIconHash, BIconPeople } from 'bootstrap-vue';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { addMinutes } from 'date-fns';
import { REST } from '@/components/RestCall';
import DatePicker from '@/components/DatePicker';
import TimePicker from '@/components/TimePicker';

export default {
  data() {
    return {
      f: {
        userUuid4: null,
        customerUuid4: null,
        startAt: null,
        duration: 60,
        seats: null,
        userMessage: null,
        recurrence: 'D',
        count: 1,
        allowedAmount: null,
        allowedCurrency: null,
        confirmDeposit: null,
        confirmWaiveCnlRight: null,
      },
      render: 0,
      date: null,
      time: null,
      mn: false,
      inpast: false,
    }
  },
  mounted() {
    this.f.userUuid4 = this.$store.getters.uuid;
    this.f.customerUuid4 = this.$store.getters.uuid;
  },
  computed: {
    isXs() {
      return this.$store.getters.isXs;
    },
    isMidNight() {
      return this.mn === true;
    },
    isInPast() {
      return this.inpast;
    },
    recOptions() {
      return [
          { value: 'D', text: this.$t('res.frecD') },
          { value: 'W', text: this.$t('res.frecW') },
          { value: 'M', text: this.$t('res.frecM') },
          { value: 'Y', text: this.$t('res.frecY') },
      ];
    },
    isLoggedIn() {
      return this.$store.state.oLogin.isLoggedIn;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    valCounted() {
      if (this.f.count == null) return null;
      return this.f.count >= 1 && this.f.count <= 500;
    },
    valSeats() {
      if (this.f.seats == null) return null;
      return (typeof this.f.seats === 'number') && this.f.seats > 0 && this.f.seats <= 11;
    },
    valMessage() {
      if (!this.f.userMessage) return null;
      return this.f.userMessage.length < 200;
    },
    valDates() {
      if (this.date == null || this.time == null || this.date == '' || this.time == '') return null;
      if (this.f.startAt == null || this.f.duration == null) return false;
      return true;
    },
    valDur() {
      if (this.date == null || this.time == null) return null;
      return !(isNaN(this.f.duration) || this.f.duration <= 0 || this.f.duration % 15 != 0 || this.f.duration > 1440);
    },
    val() {
      return !(this.valDates && this.valDur && this.valSeats && this.valCounted &&
              (this.f.userMessage == null || this.f.userMessage == '' || this.valMessage));
    },
    getDateFormat() {
      return this.$i18n.getDateTimeFormat(this.$i18n.locale).ds;
    },
    getTimeFormat() {
      return this.$i18n.getDateTimeFormat(this.$i18n.locale).t;
    },
    tz() {
      return this.$store.state.settings.timezone;
    },
    duration() {
      return this.f.duration;
    },
  },
  methods: {
    onSubmit() {
      if (this.f.userMessage == '') this.f.userMessage = null;
      REST.post('/reservations', this.f
        ).then(resp => {
          this.$store.commit('showSuccess',resp.d.message);
          this.onCancel(); // clear local data
          // this.$router.push('/reservations/next').catch(() => {}); // fwd
        }).catch(e => {
          this.$store.commit('showWarn',e.message);
        })
    },
    calcVals() {
      if (this.time == null || this.date == null) {
        this.f.startAt = null;
        return;
      }
      if (this.f.duration == null) return;
      let s = zonedTimeToUtc(this.date + ' ' + this.time, this.tz); // stored TZ
      let e = addMinutes(s, this.f.duration);

      this.mn = false;
      if (!isNaN(s.getTime()) && !isNaN(e.getTime())) {
        this.mn = this.checkMidnight(s, e) === true; // over midnight
        this.f.startAt = s.toISOString().substring(0,16).replace('T',' '); // get UTC
        this.inpast = s < Date.now();
        if (this.inpast || s.getUTCMinutes() % 15 != 0 ) {
          this.f.startAt = null;
        }
      }
    },
    onCancel() {
      this.f.seats = null;
      this.f.userMessage = null;
      this.f.recurrence = 'D';
      this.f.count = 1;
      this.date = null;
      this.time = null;
      this.mn = false;
      this.inpast = false;
      this.render += 1;
    },
    checkMidnight(a, b) {
      if (a == null || b == null) return null;
      return (utcToZonedTime(a, this.tz).getDate() !== utcToZonedTime(b, this.tz).getDate()); // could exclude finishing at midnight
    },
  },
  watch: {
    date() {
      this.calcVals();
    },
    time() {
      this.calcVals();
    },
    duration() {
      this.calcVals();
    },
    tz() {
      this.calcVals(); // update on TZ change
    },
  },
  components: {
    DatePicker,
    TimePicker,
    Icon,
    BIconPeople,
    BIconHash,
  }
}
</script>
