<template>
  <div class="mt-3" v-if="isLoggedIn">
    <h1 class="mb-3">
      {{ $t('res.t') }}
    </h1>
    <b-card class="mt-3" header-tag="nav" body-class="p-2 p-sm-4">
      <template #header>
        <b-nav card-header tabs>
          <b-nav-item v-if="isUser" to="/reservations/new" link-classes="px-2 px-sm-3" exact exact-active-class="active">
            <b-icon-calendar-plus v-if="!isXs" v-bind="iH" /> {{ $t('res.hne') }}
          </b-nav-item>
          <b-nav-item v-if="!isUser" to="/reservations/today" link-classes="px-2 px-sm-3" exact exact-active-class="active">
            <b-icon-calendar v-if="!isXs" v-bind="iH" /> {{ $t('res.htd') }}
          </b-nav-item>
          <b-nav-item to="/reservations" link-classes="px-2 px-sm-3" exact exact-active-class="active">
            <b-icon-caret-right-fill v-if="!isXs" v-bind="iH" /> {{ $t('res.hnx') }}
          </b-nav-item>
          <b-nav-item to="/reservations/canceled" link-classes="px-2 px-sm-3" exact exact-active-class="active">
            <b-icon-x v-if="!isXs" v-bind="iH" /> {{ $t('res.hca') }}
          </b-nav-item>
          <b-nav-item to="/reservations/previous" link-classes="px-2 px-sm-3" exact exact-active-class="active">
            <b-icon-caret-left-fill v-if="!isXs" v-bind="iH" /> {{ $t('res.hpr') }}
          </b-nav-item>
        </b-nav>
      </template>
      <router-view />
    </b-card>

    <ReservationsBlock v-if="!isUser">
      <router-view name="secondary" v-if="!isUser" />
    </ReservationsBlock>
  </div>
</template>

<script>
import { BIconCaretLeftFill, BIconCaretRightFill, BIconCalendarPlus, BIconX, BIconCalendar } from 'bootstrap-vue';
import ReservationsBlock from '@/components/reservations/Block';

export default {
  computed: {
    isLoggedIn() {
      return this.$store.state.oLogin.isLoggedIn;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    iH() {
      return this.$store.getters.iH;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.replace('/').catch(() => {});
    }
  },
  components: {
    ReservationsBlock,
    BIconCaretLeftFill,
    BIconCaretRightFill,
    BIconCalendarPlus,
    BIconX,
    BIconCalendar,
  },
}
</script>
